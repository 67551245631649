<template>
  <section
    v-loading="!loaded"
    class="section-block"
  >
    <div>
      <SectionTitle
        @edit="$router.push({name: 'EditPolicySetting'})"
      >
        <template #title>
          <div class="flex items-center gap-[8px]">
            <p class="font-medium" style="font-size: 24px">權益政策設定</p>
            <TipInfo>
              <p>此功能可讓店家自行設定欲揭露給顧客之權益政策(例如:個資授權、隱私權聲明)</p>
            </TipInfo>
          </div>
        </template>
      </SectionTitle>
    </div>
    <BaseElForm label-position="left" label-width="180px">
      <BaseElFormItem label="啟用狀態">
        {{ displayData.enable }}
      </BaseElFormItem>
      <BaseElFormItem label="權益政策名稱">
        {{ displayData.name }}
      </BaseElFormItem>

      <el-divider />

      <div class="flex items-center gap-[30px]">
        <p class="text-sub font-medium">將所有會員的「權益政策同意狀態」變更為「未同意」</p>
        <BaseElButton type="primary" class="rounded-full " style="padding: 7px 25px" @click="onResetMemberPolicy">
          變更
        </BaseElButton>
      </div>
    </BaseElForm>

    <ConfirmModifyMembersPolicyStatusModal
      v-if="modal.modifyMembersPolicy"
      :btn1Loading="loading.resetMemberPolicyStatus"
      @close="modal.modifyMembersPolicy = false"
      @confirm="resetMemberPolicyStatus"
    />
  </section>
</template>

<script>
import { defineComponent, reactive, computed, ref, onBeforeMount } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import ConfirmModifyMembersPolicyStatusModal from './ConfirmModifyMembersPolicyStatusModal.vue'
import { GetMemberPolicyConfig, ResetMemberPolicyStatus } from '@/api/memberPolicy'
import store from '@/store'
import { get } from 'lodash'
import TipInfo from '@/components/TipInfo.vue'

export default defineComponent({
  name: 'PolicySettings',
  components: {
    SectionTitle,
    ConfirmModifyMembersPolicyStatusModal,
    TipInfo,
  },
  setup (props) {
    const configData = ref({})
    const modal = reactive({
      edit: false,
      modifyMembersPolicy: false,
    })
    const isFirstConfig = ref(false)
    const loaded = ref(false)
    const loading = reactive({
      resetMemberPolicyStatus: false,
    })
    const orgId = computed(() => get(store.state, 'org.org.id'))
    const displayData = computed(() => {
      return {
        enable: configData.value.enable ? '啟用' : '關閉',
        name: configData.value.name || '-',
      }
    })

    const onResetMemberPolicy = async () => {
      if (isFirstConfig.value) {
        window.$message.warning('尚未編輯權益政策內容')
        return
      }
      modal.modifyMembersPolicy = true
    }

    const resetMemberPolicyStatus = async () => {
      if (loading.resetMemberPolicyStatus) return
      loading.resetMemberPolicyStatus = true
      const [, err] = await ResetMemberPolicyStatus({ orgId: orgId.value })
      loading.resetMemberPolicyStatus = false
      if (err) {
        window.$message.error(err)
        return
      }
      modal.modifyMembersPolicy = false
      window.$message.success('更新成功')
    }

    onBeforeMount(async () => {
      const [res, err] = await GetMemberPolicyConfig({ orgId: orgId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      configData.value = res
      if (!res.id) isFirstConfig.value = true
      loaded.value = true
    })

    return { modal, displayData, configData, resetMemberPolicyStatus, loaded, loading, onResetMemberPolicy }
  },
})
</script>

<style lang="postcss" scoped>

</style>
