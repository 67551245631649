<template>
  <div>
    <div v-if="!showUserInfoSetting">
      <PageTitle title="組織登入設定" hideBtn />

      <!-- 組織資料設定 -->
      <section class="section-container">
        <SectionTitle title="組織資料設定" style="margin-bottom: 24px" @edit="showOrgSettingDialog = true" />

        <BaseElForm label-position="left" label-width="180px">
          <BaseElFormItem label="Logo 設定">
            <img v-if="orgConfig.Logo" :src="imgSource(orgConfig.Logo)">
            <span v-else>尚未上傳</span>
          </BaseElFormItem>

          <BaseElFormItem label="組織名稱設定">
            <span>{{ orgConfig.orgName || '尚未上傳' }}</span>
          </BaseElFormItem>
        </BaseElForm>
      </section>

      <!-- 註冊設定 -->
      <section class="section-container">
        <SectionTitle title="註冊設定" style="margin-bottom: 24px" @edit="showUserInfoSetting = true" />

        <BaseElForm label-position="left" label-width="110px">
          <BaseElFormItem label="簡易註冊設定" label-width="110px">
            <BaseElSwitch
              v-model="formData.easyRegisterMode"
              style="margin-right: 20px"
              active-text="開啟"
              inactive-text="關閉"
              @change="easyRegisterModeChange"
            />
          </BaseElFormItem>

          <template v-if="userInfoSettingBase.length">
            <div class="section-title">
              <span class="title-hint">|</span>
              <span>註冊資料固定欄位</span>
            </div>
            <BaseElFormItem
              v-for="(item, index) in userInfoSettingBase"
              :key="item.key"
              :label="`排序${index + 1}`"
            >
              {{ item.name }}
            </BaseElFormItem>
          </template>

          <template v-if="userInfoSettingCustom.length">
            <div class="section-title">
              <span class="title-hint">|</span>
              <span>註冊資料自定義欄位</span>
            </div>
            <BaseElFormItem
              v-for="(item, index) in userInfoSettingCustom"
              :key="item.key"
              :label="`排序${index + 1}`"
            >
              {{ item.name }}
            </BaseElFormItem>
          </template>
        </BaseElForm>
      </section>

      <PolicySettings />

      <!-- Line 登入設定 -->
      <section v-loading="loading.updateLineAuthConfig" class="section-container">
        <SectionTitle title="Line登入設定" style="margin-bottom: 24px" :hideBtn="isOhbotSuccessAdmin" @edit="showLineAuthSettingDialog = true" />
        <BaseElForm label-position="left" label-width="180px">
          <template v-if="isOhbotSuccessAdmin">
            <BaseElFormItem label="關閉組織 Line Login">
              <el-switch
                v-model="formData.line.useShopLineLogin"
                style="margin-right: 20px"
                active-text="開啟"
                inactive-text="關閉"
                @change="handleOrgLineLoginUpdate"
              />
            </BaseElFormItem>
            <el-button class="float-button" type="primary" @click="recheckLineCallbackUrl">重新驗證</el-button>
            <BaseElFormItem label="尚未正確設置 Callback URL 設定之分店" label-width="350px">
              <template #label>
                <div class="grid grid-flow-col items-center">
                  <span>尚未正確設置 Callback URL 設定之分店</span>
                  <TipInfo>
                    請至 Line Developers 將組織的 Callback URL 貼至各分店 Line Login Channel 的 Callback URL 欄位。<br>
                    未正確設置 Callback URL 之店家，將無法顯示 Line 登入選項。
                  </TipInfo>
                </div>
              </template>
              <div v-if="noCallbackUrlBranch.length" class="grid gap-[4px]">
                <p v-for="item in noCallbackUrlBranch" :key="item.shopId" class="leading-normal">{{ item.shopName }}</p>
              </div>
              <p v-else>無</p>
            </BaseElFormItem>
            <el-divider />
          </template>
          <el-button v-if="isOhbotSuccessAdmin" class="float-button" type="primary" @click="showLineAuthSettingDialog = true">編輯</el-button>
          <BaseElFormItem label="開關設定">
            <BaseElSwitch
              v-model="formData.line.enable"
              style="margin-right: 20px"
              active-text="開啟"
              inactive-text="關閉"
              @change="handleLineAuthConfigUpdate"
            />
          </BaseElFormItem>
          <BaseElFormItem label="維持會員登入">
            <BaseElSwitch
              v-model="formData.line.directLineLogin"
              style="margin-right: 20px"
              active-text="開啟"
              inactive-text="關閉"
              @change="handleDirectLineAuthConfigUpdate"
            />
          </BaseElFormItem>
          <BaseElFormItem label="Callback URL">
            <div class="flex">
              <span>{{ redirectURL.line || '尚未填寫' }}</span>
              <img
                src="@/assets/icon/copy.svg"
                class="cursor-pointer"
                @click="copyUrl(redirectURL.line)"
              >
            </div>
          </BaseElFormItem>
        </BaseElForm>
      </section>

      <!-- Facebook 登入設定 -->
      <section class="section-container">
        <SectionTitle title="Facebook登入設定" style="margin-bottom: 24px" @edit="showFBAuthSettingDialog = true" />
        <BaseElForm label-position="left" label-width="180px">
          <BaseElFormItem label="開關設定">
            <BaseElSwitch
              v-model="formData.fb.enable"
              style="margin-right: 20px"
              active-text="開啟"
              inactive-text="關閉"
              @change="updateFBAuthConfig"
            />
          </BaseElFormItem>
          <BaseElFormItem label="Callback URL">
            <div class="flex">
              <span>{{ redirectURL.fb || '尚未填寫' }}</span>
              <img
                src="@/assets/icon/copy.svg"
                class="cursor-pointer"
                @click="copyUrl(redirectURL.fb)"
              >
            </div>
          </BaseElFormItem>
        </BaseElForm>
      </section>
    </div>

    <!-- Dialog -->
    <OrgSettingDialog
      v-if="showOrgSettingDialog"
      :config="orgConfig"
      @close="showOrgSettingDialog = false"
      @confirm="refresh"
    />
    <EasyModeDialog
      v-if="showEasyModeDialog"
      @close="showEasyModeDialog = false"
      @cancel="formData.easyRegisterMode = false"
      @confirm="updateAuthConfig"
    />

    <LineAuthSettingDialog
      v-if="showLineAuthSettingDialog"
      :redirectURL="redirectURL.line"
      :config="lineConfig"
      @close="showLineAuthSettingDialog = false"
      @sync="syncLineFormData($event, true)"
      @confirm="updateLineAuthConfig"
    />
    <FBAuthSettingDialog
      v-if="showFBAuthSettingDialog"
      :redirectURL="redirectURL.fb"
      :config="fbConfig"
      @close="showFBAuthSettingDialog = false"
      @sync="syncFBFormData($event, true)"
      @confirm="updateFBAuthConfig"
    />

    <OAuthUserInfoSetting
      v-if="showUserInfoSetting"
      :formData.sync="userInfoSettingFormData"
      @close="onCloseUserInfoSetting"
    />

    <DirectLineLoginWarningDialog
      v-if="showDirectLineLoginWarningDialog"
      :type="directLineLoginWarningType"
      @confirm="handleDirectLineLoginWarningDialogEvent('confirm', $event)"
      @cancel="handleDirectLineLoginWarningDialogEvent('cancel', $event)"
    />
    <LineLoginWarningDialog
      v-if="showLineLoginWarningDialog"
      :type="lineLoginWarningType"
      @confirm="handleLineLoginWarningDialogEvent('confirm', $event)"
      @cancel="handleLineLoginWarningDialogEvent('cancel', $event)"
    />
    <OrgLoginSettingDialog
      v-if="showOrgLoginSettingDialog"
      :noCallbackUrlBranch="noCallbackUrlBranch"
      @close="closeOrgLoginSettingDialog"
      @cancel="closeOrgLoginSettingDialog"
      @confirm="handleOrgLoginDialogEvent"
    />
  </div>
</template>

<script>
import DirectLineLoginWarningDialog from '@/views/Basic/Dialog/DirectLineLoginWarningDialog.vue'
import LineLoginWarningDialog from '@/views/Basic/Dialog/LineLoginWarningDialog.vue'
import EasyModeDialog from '@/views/Basic/Dialog/EasyModeDialog.vue'
import OrgSettingDialog from '@/views/Basic/Dialog/OrgSettingDialog.vue'
import LineAuthSettingDialog from '@/views/Basic/Dialog/LineAuthSettingDialog.vue'
import FBAuthSettingDialog from '@/views/Basic/Dialog/FBAuthSettingDialog.vue'
import OAuthUserInfoSetting from '@/views/Basic/OAuthUserInfoSetting.vue'
import PolicySettings from './components/PolicySettings.vue'
import TipInfo from '@/components/TipInfo.vue'
import OrgLoginSettingDialog from '@/views/Basic/Dialog/OrgLoginSettingDialog.vue'

import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import {
  GetOrgAuthConfig,
  GetLineAuthConfig,
  GetFBAuthConfig,
  GetLineAuthRedirectUrl,
  GetFBAuthRedirectUrl,
  GetUserInfoSetting,
  UpdateLineAuthConfig,
  UpdateFBAuthConfig,
  UpdateOrgAuthConfig,
  RecheckLineLoginConfigCallbackUrl,
  FindLineLoginConfigCallbackUrl,
} from '@/api/org/org'
import { mapGetters } from 'vuex'
import imageMixin from '@/mixin/image'
import store from '@/store'
import { get, filter } from 'lodash'
import copy from 'clipboard-copy'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'OAuthSetting',
  components: {
    OrgSettingDialog,
    LineAuthSettingDialog,
    FBAuthSettingDialog,
    EasyModeDialog,
    OAuthUserInfoSetting,
    DirectLineLoginWarningDialog,
    LineLoginWarningDialog,
    PolicySettings,
    TipInfo,
    OrgLoginSettingDialog,
  },
  mixins: [imageMixin],
  setup () {
    const { checkAction } = usePermissions()
    const loading = reactive({
      updateLineAuthConfig: false,
    })
    const modal = reactive({})
    const noCallbackUrlBranch = ref([])
    const orgId = computed(() => get(store.state, 'org.org.id'))
    const isOhbotSuccessAdmin = computed(() => checkAction('admin.authServiceLineOauthConfig.showUseShopLineLogin'))
    const recheckLineCallbackUrl = async () => {
      if (loading.updateLineAuthConfig) return
      loading.updateLineAuthConfig = true
      const [, err] = await RecheckLineLoginConfigCallbackUrl({
        orgId: orgId.value,
      })
      loading.updateLineAuthConfig = false
      if (err) return window.$message.error(err)
      await findLineLoginConfigCallbackUrl()
      window.$message.success('驗證成功！')
    }
    const findLineLoginConfigCallbackUrl = async () => {
      if (loading.updateLineAuthConfig) return
      loading.updateLineAuthConfig = true
      const [res, err] = await FindLineLoginConfigCallbackUrl({
        orgId: orgId.value,
      })
      loading.updateLineAuthConfig = false
      if (err) return window.$message.error(err)
      noCallbackUrlBranch.value = filter(res.validCallbackUrls, item => !item.validCallbackUrl)
    }
    onMounted(async () => {
      if (isOhbotSuccessAdmin.value) await findLineLoginConfigCallbackUrl()
    })
    return {
      loading,
      modal,
      isOhbotSuccessAdmin,
      recheckLineCallbackUrl,
      noCallbackUrlBranch,
    }
  },
  data: () => ({
    imgSize: 150,
    orgConfig: {},
    lineConfig: {},
    fbConfig: {},
    showEasyModeDialog: false,
    showOrgSettingDialog: false,
    showLineAuthSettingDialog: false,
    showFBAuthSettingDialog: false,
    showDirectLineLoginWarningDialog: false,
    showLineLoginWarningDialog: false,
    showOrgLoginSettingDialog: false,
    directLineLoginWarningType: 'enable',
    lineLoginWarningType: 'enable',

    formData: {
      easyRegisterMode: false,
      line: {
        enable: false,
        directLineLogin: false,
        channelID: '',
        channelSecret: '',
        useShopLineLogin: false,
      },
      fb: {
        enable: false,
        appID: '',
        appSecret: '',
      },
    },
    redirectURL: {
      line: '',
      fb: '',
    },
    showUserInfoSetting: false,
    userInfoSettingFormData: [],
  }),

  computed: {
    ...mapGetters(['shop', 'orgId']),
    userInfoSettingBase () {
      return this.userInfoSettingFormData.filter(item => item.isBase)
    },
    userInfoSettingCustom () {
      return this.userInfoSettingFormData.filter(item => !item.isBase)
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    copyUrl (url) {
      copy(url)
      this.$message.success('已成功複製連結！')
    },

    async getOrgAuthConfig () {
      const [res, err] = await GetOrgAuthConfig({
        orgId: this.orgId,
      })

      if (err) {
        if (err.status === 404) return
        return this.$message.error(err)
      }
      this.orgConfig = res
      this.formData.easyRegisterMode = res.easyRegisterMode
    },

    async getUserInfoSetting () {
      const [res, err] = await GetUserInfoSetting({
        orgId: this.orgId,
      })

      if (err) {
        if (err.status === 404) return
        return this.$message.error(err)
      }
      this.userInfoSettingFormData = res.fields
    },

    async getLineAuthConfig () {
      const [res, err] = await GetLineAuthConfig({
        orgId: this.orgId,
      })
      if (err) {
        if (err.status === 404) return
        return this.$message.error(err)
      }
      this.lineConfig = res
      this.syncLineFormData(res)
    },

    async getFBAuthConfig () {
      const [res, err] = await GetFBAuthConfig({
        orgId: this.orgId,
      })
      if (err) {
        if (err.status === 404) return
        return this.$message.error(err)
      }
      this.fbConfig = res
      this.syncFBFormData(res)
    },

    //= > 取得 RedirectUrl
    // line
    async getLineAuthRedirectUrl () {
      const [res, err] = await GetLineAuthRedirectUrl({ orgId: this.orgId })
      if (err) {
        if (err.status === 404) return
        return this.$message.error(err)
      }
      this.redirectURL.line = res.redirectUrl
    },
    // fb
    async getFBAuthRedirectUrl () {
      const [res, err] = await GetFBAuthRedirectUrl({ orgId: this.orgId })
      if (err) {
        if (err.status === 404) return
        return this.$message.error(err)
      }
      this.redirectURL.fb = res.redirectUrl
    },
    // 處理Line登入開關
    async handleLineAuthConfigUpdate (enable) {
      // 開啟line登入
      if (enable) {
        this.lineLoginWarningType = 'enable'
        return await this.updateLineAuthConfig()
      }
      // 關閉line登入
      if (!enable) {
        if (this.formData.line.directLineLogin) {
          this.lineLoginWarningType = 'disable'
          this.showLineLoginWarningDialog = true
        }
        if (!this.formData.line.directLineLogin) {
          await this.updateLineAuthConfig()
        }
      }
    },
    // 關閉組織Line Login
    async handleOrgLineLoginUpdate (enable) {
      // 開啟組織line登入
      if (enable) {
        this.showOrgLoginSettingDialog = true
      }
      // 關閉組織line登入
      if (!enable) {
        await this.updateLineAuthConfig()
      }
    },
    async handleOrgLoginDialogEvent () {
      this.updateLineAuthConfig()
      this.showOrgLoginSettingDialog = false
    },
    async closeOrgLoginSettingDialog () {
      this.showOrgLoginSettingDialog = false
      this.formData.line.useShopLineLogin = false
    },
    // 處理Line維持登入開關
    async handleDirectLineAuthConfigUpdate (enable) {
      // 開啟維持登入
      if (enable) {
        this.directLineLoginWarningType = 'enable'
        this.showDirectLineLoginWarningDialog = true
      }
      // 關閉維持登入
      if (!enable) {
        if (this.formData.line.enable) await this.updateLineAuthConfig()
      }
    },
    async handleDirectLineLoginWarningDialogEvent (eventType, type) {
      if (eventType === 'confirm') {
        if (type === 'enable') {
          if (!this.formData.line.enable) {
            this.formData.line.enable = true
            this.showDirectLineLoginWarningDialog = false
            return await this.updateLineAuthConfig()
          }
          if (this.formData.line.enable) {
            this.showDirectLineLoginWarningDialog = false
            return await this.updateLineAuthConfig()
          }
        }
      }
      if (eventType === 'cancel') {
        if (type === 'enable') {
          this.formData.line.directLineLogin = false
          this.showDirectLineLoginWarningDialog = false
        }
      }
    },
    async handleLineLoginWarningDialogEvent (eventType, type) {
      if (eventType === 'cancel') {
        if (type === 'disable') {
          this.formData.line.enable = true
          this.showLineLoginWarningDialog = false
          return
        }
      }
      if (eventType === 'confirm') {
        if (type === 'disable') {
          this.formData.line.directLineLogin = false
          this.showLineLoginWarningDialog = false
          return await this.updateLineAuthConfig()
        }
      }
    },
    onCloseUserInfoSetting () {
      this.showUserInfoSetting = false
      const container = document.querySelector('.app-main.views-container')
      if (container) {
        container.scrollTo(0, 0)
      }
    },
    async updateLineAuthConfig () {
      const [, err] = await UpdateLineAuthConfig({
        orgId: this.orgId,
        enable: Boolean(this.formData.line.enable),
        directLineLogin: this.formData.line.directLineLogin,
        channelID: this.formData.line.channelID,
        channelSecret: this.formData.line.channelSecret,
        useShopLineLogin: this.formData.line.useShopLineLogin,
      })
      if (err) {
        if (err.includes('維持會員登入必須啟用LINE登入')) {
          this.showDirectLineLoginWarningDialog = true
          return
        }
        this.$message.error(err)
        return
      }
      this.$message.success('更新成功！')
      this.refresh()
    },

    async updateFBAuthConfig () {
      const [, err] = await UpdateFBAuthConfig({
        orgId: this.orgId,
        enable: this.formData.fb.enable,
        appID: this.formData.fb.appID,
        appSecret: this.formData.fb.appSecret,
      })
      if (err) return this.$message.error(err)
      this.$message.success('更新成功！')
      this.refresh()
    },

    async updateAuthConfig () {
      const [, err] = await UpdateOrgAuthConfig({
        orgId: this.orgId,
        orgName: this.orgConfig.orgName,
        easyRegisterMode: this.formData.easyRegisterMode,
      })
      if (err) return this.$message.error(err)
      this.$message.success('更新成功！')
      this.refresh()
    },

    syncLineFormData (data, fromDialog = false) {
      if (fromDialog) {
        this.formData.line.channelID = data.channelId
        this.formData.line.channelSecret = data.channelSecret
        return
      }
      this.formData.line.enable = data.enable
      this.formData.line.channelID = data.channelID
      this.formData.line.channelSecret = data.channelSecret
      this.formData.line.directLineLogin = data.directLineLogin
      this.formData.line.useShopLineLogin = data.useShopLineLogin
    },
    syncFBFormData (data, fromDialog = false) {
      if (fromDialog) {
        this.formData.fb.appID = data.appId
        this.formData.fb.appSecret = data.appSecret
        return
      }
      this.formData.fb.enable = data.enable
      this.formData.fb.appID = data.appID
      this.formData.fb.appSecret = data.appSecret
    },
    easyRegisterModeChange (val) {
      if (val) this.showEasyModeDialog = true
      if (!val) this.updateAuthConfig()
    },

    async refresh () {
      await Promise.all([
        this.getOrgAuthConfig(),
        this.getUserInfoSetting(),
        this.getLineAuthConfig(),
        this.getFBAuthConfig(),
        this.getLineAuthRedirectUrl(),
        this.getFBAuthRedirectUrl(),
      ])
    },
  },
})
</script>

<style lang="postcss" scoped>
.section-container {
  @apply bg-white px-[29px] py-[24px] rounded-[4px];
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.1);
}

.sub-title {
  @apply flex justify-between items-center;
  @apply mb-[24px] text-[24px] font-medium;
}
.title-hint {
  color: var(--primary-100);
  @apply mr-[5px] items-center;
}

.section-title {
  @apply mb-[28px] leading-[24px];
}
.float-button {
  @apply float-right min-w-[100px] text-normal relative;
  z-index: 10;
}
</style>
