<template>
  <div>
    <el-dialog
      title="提示"
      :visible="true"
      width="600px"
      :close-on-click-modal="false"
      @close="$emit('close')"
    >
      <div>
        請注意，分店 Line Login 須正確設定 Callback 才能於註冊時顯示「Line 登入選項」。
      </div>
      <div v-if="noCallbackUrlBranch.length" class="grid gap-[4px] mt-[20px]">
        <p v-for="item in noCallbackUrlBranch" :key="item.shopId">{{item.shopName}}</p>
      </div>
      <p v-else class="mt-[20px]">無</p>
      <div slot="footer">
        <el-button plain @click="$emit('cancel')">取消</el-button>
        <el-button type="primary" @click="$emit('confirm')">確認</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'OrgLoginSettingDialog',
  props: {
    noCallbackUrlBranch: {
      type: Array,
      default: () => [],
    },
  }
}
</script>
