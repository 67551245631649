<template>
  <el-dialog
    title="Line登入設定"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <BaseElFormItem label="CallbackUrl" prop="callbackUrl">
        <span>{{ redirectURL }}</span>
      </BaseElFormItem>

      <BaseElFormItem label="Channel ID" prop="channelId">
        <BaseElInput v-model="formData.channelId" :disabled="inputDisabled" placeholder="請填寫" />
      </BaseElFormItem>

      <BaseElFormItem label="Channel Secret" prop="channelSecret">
        <BaseElInput v-model="formData.channelSecret" :disabled="inputDisabled" placeholder="請填寫" />
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="cancel">取消</BaseElButton>
      <BaseElButton type="primary" @click="confirm">儲存</BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
export default {
  name: 'LineAuthSettingDialog',
  props: ['config', 'redirectURL', 'sync'],
  data: () => ({
    formData: {
      callbackUrl: '',
      channelId: '',
      channelSecret: '',
    },
    formRules: {
      callbackUrl: '',
      channelId: [noEmptyRules()],
      channelSecret: [noEmptyRules()],
    },
    inputDisabled: false,
  }),
  watch: {
    config () {
      this.syncConfig()
    },
  },

  mounted () {
    this.syncConfig()
  },
  methods: {
    async confirm () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      this.$emit('sync', this.formData)
      this.$emit('confirm')
      this.$emit('close')
    },

    cancel () {
      this.$emit('cancel')
      this.$emit('close')
    },

    syncConfig () {
      const config = JSON.parse(JSON.stringify(this.config))
      this.formData.channelId = config.channelID
      this.formData.channelSecret = config.channelSecret
      this.inputDisabled = config.useShopLineLogin
    },
  },
}
</script>
