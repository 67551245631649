<template>
  <div>
    <el-dialog
      title="提示"
      :visible="true"
      width="400px"
      :close-on-click-modal="false"
      @close="$emit('cancel', type)"
    >
      <div>
        開啟此功能將限制會員僅能透過 Line 登入/註冊，<br>
        開啟後能使會員維持登入的狀態。
      </div>

      <div slot="footer">
        <BaseElButton plain @click="$emit('cancel', type)">取消</BaseElButton>
        <BaseElButton type="primary" @click="$emit('confirm', type)">確認</BaseElButton>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'DirectLineLoginWarningDialog',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
}
</script>
